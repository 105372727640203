import React from "react";
import StretchedLinkCards from "../card/stretched-link-cards";
import useCardBlogsData from "../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../helpers/getFeaturedCardBlogData";

const ArticleCategoriesSection = ({ categoryName, articles }) => {
  const CARDS = articles
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((article) => {
      // console.log("Article: ", article.title, article);
      return article.slug;
    });

  const CARDS_DATA = useCardBlogsData(CARDS);

  const FIXED_CARDS = getFeaturedCardBlogData(CARDS_DATA, {
    titleClass: "text-success",
    hasText: false,
    idString: "blog-card-",
    useSlugOnId: true
  });

  return (
    <section id="article-categories-section" className="bg-white">
      <div className="container">
        <h1 className="mb-4">Articles about {categoryName}</h1>
        {CARDS && (
          <StretchedLinkCards
            sectionId="blog-category-stretched-link-cards"
            cards={FIXED_CARDS}
            containerClass="px-0"
            sectionClass=""
          />
        )}
      </div>
    </section>
  );
};
export default ArticleCategoriesSection;
